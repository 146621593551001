<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1">
          <input
            type="text"
            v-model="searchParams.search"
            v-on:keyup.enter="handleSearch"
            class="form-control form-control-solid w-250px me-2"
            :placeholder="constants.presses.SEARCH_PRESSES"
          />
        </div>
        <el-tooltip class="box-item" effect="dark" :content="constants.general.SEARCH" placement="top">
          <button type="button" @click.prevent="handleSearch" class="btn btn-icon btn-light btn-sm me-2"><i class="bi bi-search"></i></button>
        </el-tooltip>
        <el-tooltip class="box-item" effect="dark" :content="constants.general.RESET" placement="top">
          <button type="button" @click.prevent="resetSearch" class="btn btn-icon btn-light btn-sm"><i class="bi bi-arrow-counterclockwise"></i></button>
        </el-tooltip>
      </div>
      <div class="card-toolbar">
        <button type="button" class="btn btn-primary" v-if="permission.isAddAllowed" @click="$router.push({ name: 'add-press' })"><i class="bi bi-plus-circle-fill fs-4"></i>
          {{ constants.general.ADD }}
        </button>
      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :rows-per-page="searchParams.recordsPerPage"
        :empty-table-text="noDataFound"
        :loading="loading"
        :current-page="1"
        :enable-items-per-page-dropdown="true"
        :total="total"
        ref="updateProps"
        @current-change="handleCurrentChange"
        @items-per-page-change="handleSizeChange"
      >
        <template v-slot:cell-id="{ row: data }">
          {{ data }}
        </template>
        <template v-slot:cell-name="{ row: data }">
          {{ data.name }}
        </template>
        <template v-slot:cell-description="{ row: data }">
          {{ data.description }}
        </template>
        <template v-slot:cell-actions="{ row: data }">
           <div v-if="permission.isEditAllowed || permission.isDeleteAllowed">
              <el-tooltip class="box-item" v-if="permission.isEditAllowed" effect="dark" :content="constants.general.EDIT" placement="top">
                <button type="button"  @click.prevent="$router.push({ name: 'edit-press', params: { id: data.id } })" class="btn btn-icon btn-light btn-sm me-2">
                  <i class="bi bi-pencil-square"></i>
                </button>
              </el-tooltip>
              <el-tooltip class="box-item" v-if="permission.isDeleteAllowed" effect="dark" :content="constants.general.DELETE" placement="top">
                <button type="button"  @click.prevent="remove(data.id, tableData.findIndex((i) => i.id === data.id))" class="btn btn-icon btn-light btn-sm">
                  <i class="bi bi-trash text-danger"></i>
                </button>
              </el-tooltip>
           </div>
           <div v-else>
             -
           </div>
        </template>
      </Datatable>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { notificationFire } from "@/composable/notification.js";
import { ElMessageBox } from "element-plus";
import { useRoute } from "vue-router";
import { formatText } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
import { Actions } from "@/store/enums/RememberSearchEnums";
import { useStore } from "vuex";

export default {
  name: "presses-listing",
  components: {
    Datatable
  },
  setup() {
    let total = ref(0);
		const store = useStore();
    const tableData = ref([]);
    const loading = ref(false);
    const updateProps = ref(null);
    const route = useRoute();
    const moduleName = ref((route.meta.module) ? route.meta.module : '');
    const constants = globalConstant;
    const noDataFound = ref(globalConstant.general.LOADING);
    const searchParams = reactive({
      search: store.getters.getSearch(moduleName.value),
      recordsPerPage: store.getters.getPerPage(moduleName.value),
      pageNub: store.getters.getPageNumber(moduleName.value),
    });
    const permission = reactive({
      isAddAllowed: false,
      isEditAllowed: false,
      isDeleteAllowed: false,
    })
    const tableHeader = ref([
      {
        name: constants.general.SRNO,
        key: "index",
        sortable: false,
        width: "w-10px",
      },
      {
        name: constants.presses.NAME,
        key: "name",
        sortable: true,
        width: "w-100px",
      },
      {
        name: constants.presses.DESCRIPTION,
        key: "description",
        width: "w-200px",
        sortable: false,
      },
      {
        name: constants.general.ACTIONS,
        key: "actions",
        width: "w-200px",
        sortable: false,
      },
    ]);

    //Get all presses
    const getPresses = async () => {
      loading.value = true;
      await ApiService.query("presses", { params: searchParams })
        .then(({ data }) => {
          if (data.data.data.length) {
            tableData.value.splice(0, tableData.value.length, ...data.data.data);
            total.value = data.data.total;
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub);
          } else {
            tableData.value = [];
            total.value = 0;
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, globalConstant.general.NO_DATA_FOUND);
          }
          setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
          loading.value = false;
        })
        .catch((error) => {
          tableData.value = [];
          loading.value = false;
        });
    };

    //Remove press data from listing page
    const remove = async (pressID, index) => {
      ElMessageBox.confirm(
        constants.general.DELETE_MESSAGE,
        constants.general.ALERT_WARNING,
        {
          confirmButtonText: constants.general.ALERT_CONFIMATION,
          cancelButtonText: constants.general.ALERT_CANCEL,
          type: 'warning',
          center: true
        }
      ).then(() => {
        ApiService.delete("presses/" + pressID)
          .then((response) => {
            let status = response.status;
            let message = response.data.message;
            if (status == 200) {
              notificationFire(message, "success");
              tableData.value.splice(index, 1);
              total.value = total.value > 0 ? total.value - 1 : 0;
              setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]
              );
            }
            if(tableData.value.length == 0){
                updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, globalConstant.general.NO_DATA_FOUND);
            }
          })
          .catch((error) => {
            let message = error.message;
            notificationFire(message, "error");
          });
      });
    };

    //Page size change event
    const handleSizeChange = (number) => {
			store.dispatch(Actions.PER_PAGE, {'module':moduleName.value,'value':number});
      searchParams.recordsPerPage = store.getters.getPerPage(moduleName.value);
      handleCurrentChange(1);
    };

    //Page change event
    const handleCurrentChange = (number) => {
			store.dispatch(Actions.PAGE_NUMBER, {'module':moduleName.value,'value':number});
      searchParams.pageNub = store.getters.getPageNumber(moduleName.value);
      getPresses();
    };

    //Handle search event
    const handleSearch = () => {
			store.dispatch(Actions.SEARCH, {'module':moduleName.value,'value':searchParams.search});
      handleCurrentChange(1);
    };

    //Reset search value
    const resetSearch = () => {
      if (searchParams.search.length > 0) {
        searchParams.search = "";
				store.dispatch(Actions.SEARCH, {'module':moduleName.value,'value':searchParams.search});
        getPresses();
      }
    };

    //Set breadcrumbs and get the presses
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{ name: formatText(route.name), active: true }]);
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("createPress") || role == "Super Admin")
        permission.isAddAllowed = true;
      if (per.includes("updatePress") || role == "Super Admin")
        permission.isEditAllowed = true;
      if (per.includes("deletePress") || role == "Super Admin")
        permission.isDeleteAllowed = true;
      await getPresses();
    });

    return {
      tableData,
      getPresses,
      total,
      loading,
      searchParams,
      handleSearch,
      handleSizeChange,
      handleCurrentChange,
      remove,
      resetSearch,
      tableHeader,
      noDataFound,
      updateProps,
      constants,
      permission
    };
  },
};
</script>
<style lang="scss">
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
</style>